<!-- 校本资源-教材 -->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="teaching-material">
    <div class="right-box">
      <div class="search-box">
        <div class="search-item">
          <div class="title">版本：</div>
          <p
            v-for="(item, key) in textbookVersion"
            :key="key"
            class="p"
            :class="{ on: currentVersion == item.editionId }"
            @click="setVersion(item, key)"
          >
            {{ item.editionName }}
          </p>
        </div>
        <div class="search-item search-item-two">
          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <div
          v-for="(item, index) in resourceTypeList"
          :key="index"
          class="tab-item"
          :class="{ active: currentTypeId == index }"
          @click="resourceTabClickHandle(index)"
        >
          <div class="type-name">{{ item }}</div>
          <div v-if="currentTypeId == index" class="underline"></div>
        </div>
      </div>

      <div class="list-box">
        <div
          v-for="(v, i) in pageData"
          :key="i"
          class="item-list"
          :class="{ mr40: (i + 1) % 6 != 0 }"
        >
          <!-- 缩略图 -->
          <div class="base-info">
            <div class="thumbnail" @click="toDetail(v.id)">
              <img :src="v.cover" alt="" />
            </div>
            <div class="star">
              <template v-if="v.relationId && v.relationId > 0">
                <i class="el-icon-star-on" @click="cancel(v)"></i>
              </template>
              <template v-else>
                <i class="el-icon-star-off" @click="addList(v)"></i>
              </template>
            </div>
          </div>
          <div class="name" @click="toDetail(v.id)">
            {{ v.editionName }}{{ v.gradeName
            }}{{ getLabelByValue(v.subjectId, subjectOptions) }}{{ v.name }}
          </div>
        </div>
        <div v-if="pageData.length == 0" class="none-list">
          <img src="@/static/resources/none.svg" alt="" />
          <p>暂未找到数据</p>
        </div>
      </div>
      <div v-if="total != 0" class="list-box-bottom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="searchItems.size"
          :current-page="pageIndex"
          @current-change="pageList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  teachbookrelation,
  favBooksObj,
  unfavBooksObj,
  getTextbooksVersion,
} from "@/core/api/resource/schoolResource";
import { setStore, getStore, removeStore } from "@/core/util/store";
import { updateViewCount } from "@/core/api/resource/myResource";
import { subjectOptions } from "@/core/util/constdata";
const menuList = [
  {
    name: "校本教材",
    type: 1,
    isClick: true,
    img: require("@/static/resources/group.png"),
  },
  {
    name: "校本收藏",
    type: 2,
    isClick: false,
    img: require("@/static/resources/collect.png"),
  },
];
export default {
  name: "TachingPlan",
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    let resourceTypeList = ["试题", "试卷", "教材", "作业卡"];
    if (!hasHomework) {
      resourceTypeList = resourceTypeList.filter((item) => item != "作业卡");
    }

    return {
      fullscreenLoading: false,
      menuList: menuList,
      activeName: "first",
      subjectOptions: subjectOptions,
      textbookVersion: [], //版本
      currentTypeId: 1, //0-试题 1-试卷 2-教案 3-教材 4-微课
      resourceTypeList: resourceTypeList,
      interactiveData: this.resourseTypeData,
      currentVersion: 0, //教材版本，全部
      // 搜索
      searchItems: {
        pageType: 1, //1-校本教材 2-校本收藏
        level: "",
        subjectId: "",
        editionId: 0, //教材版本
        startTime: "",
        endTime: "",
        keyword: "",
        size: 12,
        current: 1,
        rType: 1,
        type: 0,
      },
      // option数据
      pageIndex: 1,
      pageData: [],
      searchYearList: [],
      dateValue: [], //收藏日期
      total: 0,
      CDN: "https://static.wtjy.com/",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    dateValue() {
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      }
    },
  },
  created() {
    // this.$nextTick(() => {
    this.resetBaseData();
    this.onloadList();
    this.getList();
    // });
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    resourceTabClickHandle(type) {
      this.$parent.changeResourceType(type, this.searchItems, true);
    },
    resetBaseData() {
      this.searchItems.subjectId = this.getSubjectId;
      this.searchItems.level = this.getLevelId;
      if (!this.dataIsEmpty(this.interactiveData.type)) {
        this.currentTypeId = this.interactiveData.type;
        this.searchItems = Object.assign(
          this.searchItems,
          this.interactiveData.searchItems
        );
        this.resetSearchItemsByStorage();
      }
      let page_storage = getStore({ name: "schoolResourcePage" });
      if (page_storage) {
        this.searchItems.current = page_storage.page.current;
        this.pageIndex = page_storage.page.current;
        removeStore({ name: "schoolResourcePage" });
      }
    },
    resetSearchItemsByStorage() {
      this.currentVersion = this.searchItems.editionId;
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    //取消收藏
    cancel(val) {
      this.$confirm("确认取消收藏当前教材吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavBooksObj(val.id).then(() => {
            this.setList(val.id, true);
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“取消收藏”操作",
          });
        });
    },
    // 收藏
    addList(val) {
      favBooksObj(val.id).then(() => {
        this.setList(val.id, false);
        this.$message.success("收藏成功");
      });
    },
    toDetail(id) {
      setStore({
        name: "currentItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      setStore({
        name: "schoolResourcePage",
        content: { page: { current: this.searchItems.current } },
      });
      updateViewCount(id);
      this.$router.push({
        name: "TeachingMaterialDetail",
        query: {
          id: id,
        },
      });
    },

    setList(id, type) {
      this.pageData.map((index) => {
        if (id == index.id) {
          if (type) {
            index.relationId = 0;
          } else {
            index.relationId = 1;
          }
        }
      });
    },
    setVersion(item) {
      this.searchItems.editionId = item.editionId;
      this.currentVersion = item.editionId;
      this.searchItems.current = 1;
      this.getList();
    },

    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.searchList();
      }
    },
    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    refreshSearchItemsData() {
      this.searchItems.rType = this.menuList.filter(
        (item) => item.isClick
      )[0].type;

      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }
      this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
    },
    getList() {
      this.refreshSearchItemsData();
      this.fullscreenLoading = true;
      teachbookrelation(this.searchItems).then((res) => {
        this.fullscreenLoading = false;
        if (res.data.data) {
          this.pageData = res.data.data.records;
          this.total = res.data.data.total;
          this.pageData.forEach((item) => {
            item.cover = this.CDN + "res/book/" + item.id + "/img/1.png";
          });
        } else {
          this.pageData = [];
          this.total = 0;
        }
      });
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },
    // 初始化一下需要使用的搜索数组
    onloadList() {
      //搜索用的版本
      getTextbooksVersion({
        levelId: this.getLevelId,
        subjectId: this.searchItems.subjectId,
      }).then((res) => {
        this.textbookVersion = [{ editionId: 0, editionName: "全部" }];
        let textBooks = res.data.data;
        textBooks.forEach((item) => {
          item.isClick = false;
          this.textbookVersion.push(item);
        });
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      this.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.getList();
        }
      });
    },
    viewPaper(examPaperId) {
      this.$router.push({
        name: "viewPaper",
        query: {
          id: examPaperId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.teaching-material {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
}
</style>
<style scoped lang="scss">
.teaching-material {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .right-box {
    padding: 20px;
    border: 1px solid #dddddd;
  }
  .right-box {
    width: 100%;
    .resource-tab {
      display: flex;
      padding: 0 15px;
      border-bottom: 2px solid rgba(16, 198, 148, 0.2);
      margin-top: 32px;
      .tab-item {
        position: relative;
        padding-bottom: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        .type-name {
          height: 20px;
        }
        .underline {
          visibility: inherit;
          height: 3px;
          background-color: #2474ed;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          width: 28px;
        }
      }
      .active {
        color: #2474ed;
      }
    }
    .list-box {
      margin-top: 20px;
      // padding: 0 8px;
      display: flex;
      flex-wrap: wrap;
      .item-list {
        margin-bottom: 48px;
        position: relative;
        .el-icon-star-off,
        .el-icon-star-on {
          color: $primary-color;
          font-size: 18px;
          vertical-align: bottom;
          cursor: pointer;
        }
        .base-info {
          height: 260px;
          padding: 14px 9px;
          margin-bottom: 10px;
          border: 1px solid #e0e0e0;
          .thumbnail {
            width: 160px;
            height: 230px;
            background-color: aliceblue;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .star {
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
        .name {
          text-align: center;
          cursor: pointer;
          max-width: 180px;
        }
      }
      .mr40 {
        margin-right: 19px;
      }
      .none-list {
        width: 100%;
        min-height: 469px;
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }
    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
    }
    .search-item {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 10px;
      align-items: center;
      .el-radio {
        margin-bottom: 0;
        line-height: 24px;
      }
      .input-with-select {
        width: 300px;
      }
      .title {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
      }
      p {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .on {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .search-item-two {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
