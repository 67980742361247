<template>
  <!-- <div class="schoolResource"> -->
  <div class="resource-paper">
    <div class="left-box">
      <div class="label" @click="getClassifiedPapers(0, 0)">全部试卷</div>
      <div v-for="(item, key) in paperTypeData" :key="key" class="grade">
        <div class="grade-name">
          {{ item.label + "年级" }}
          {{ "(" + item.count + ")" }}
        </div>
        <div class="paper-type">
          <div
            v-for="(val, index) in item.children"
            :key="index"
            class="type-name"
            :class="{
              'current-type':
                val.type == currentSelectedPaperType &&
                searchItems.resGradeId == item.value,
            }"
            @click="getClassifiedPapers(item.value, val.type)"
          >
            {{ val.label }}
            {{ "(" + val.count + ")" }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="search-box">
        <div class="search-item search-item-old">
          <div>
            日期：
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :unlink-panels="true"
              @change="searchList"
            >
            </el-date-picker>
          </div>

          <el-input
            v-model="searchItems.keyword"
            placeholder="请输入内容"
            class="input-with-select"
            @keyup.enter="searchList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </div>
        <div class="sort-box">
          <div
            class="sort-type"
            :class="{ current: isCurrentSortType == 'new' }"
            @click="getLastestPapers"
          >
            最新
            <i
              class="el-icon-sort-down sort-icon"
              :class="{ 'current-sort': searchItems.timeOrder == 2 }"
            ></i>
            <i
              class="el-icon-sort-up sort-icon"
              :class="{ 'current-sort': searchItems.timeOrder == 1 }"
            ></i>
          </div>
          <div
            class="sort-type"
            :class="{ current: isCurrentSortType == 'hot' }"
            title="按最近一周浏览数排序"
            @click="getHotestPapers"
          >
            浏览
            <i
              class="el-icon-sort-down sort-icon"
              :class="{ 'current-sort': searchItems.viewOrder == 2 }"
            ></i>
            <i
              class="el-icon-sort-up sort-icon"
              :class="{ 'current-sort': searchItems.viewOrder == 1 }"
            ></i>
          </div>
          <div class="sort-space"></div>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <div
          v-for="(item, key) in resourceTypeList"
          :key="key"
          class="tab-item"
          :class="{ active: currentTypeId == key }"
          @click="resourceTabClickHandle(key)"
        >
          <div class="type-name">{{ item }}</div>
          <div v-if="currentTypeId == key" class="underline"></div>
        </div>
      </div>
      <div class="list-box">
        <div
          v-for="(v, i) in pageData"
          :key="i"
          v-loading="fullscreenLoading"
          class="item-list"
        >
          <p>
            <span class="title" @click="viewPaper(v.id)">{{ v.name }}</span>
            <template v-if="v.relationId && v.relationId > 0">
              <i class="el-icon-star-on" @click="cancel(v)"></i>
            </template>
            <template v-else>
              <i class="el-icon-star-off" @click="addList(v)"></i>
            </template>
          </p>
          <div class="my-collect-box">
            <div class="collect-box-left">
              <span>
                {{ v.resGradeId | setName(gradeOptions, "学段") }}
                <template v-if="v.year != 0"> {{ v.year }}</template>
              </span>
              <span>|</span>
              <span>浏览{{ v.countView }}次</span>
              <span>|</span>
              <span>{{ v.type | setName(examTypeOptions, "类型") }}</span>
              <span>|</span>
              <span>{{ v.examDate || v.collectionTime }}</span>
            </div>
            <div>
              <!-- <el-button type="primary" class="btn-all">
                添加自定义<i class="el-icon-collection el-icon--right"></i>
              </el-button> -->
              <!-- @click="analyseExamPaper(v)" -->
              <el-button
                type="primary"
                class="btn-all"
                @click.stop="downloadExamPaper(v)"
                >原卷下载<i class="el-icon-download el-icon--right"></i
              ></el-button>
              <el-button
                type="primary"
                class="btn-all"
                @click="analyseExamPaper(v)"
                >试卷分析<i class="el-icon-finished el-icon--right"></i
              ></el-button>
            </div>
          </div>
        </div>
        <div
          v-if="pageData.length == 0"
          v-loading="fullscreenLoading"
          class="none-list"
        >
          <img src="@/static/resources/none.svg" alt="" />
          <p>暂未找到数据</p>
        </div>
        <div v-if="total != 0" class="list-box-bottom">
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="total"
            :current-page="pageIndex"
            @current-change="pageList"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
    ></ExamPaperAnalysisForm>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  examTypeOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  primaryOptions,
  gradeOptions,
} from "@/core/util/constdata";
import { favObj, unfavObj } from "@/core/api/resource/myResource";
import { mapGetters } from "vuex";
import {
  // upload,
  getexampaperzip,
  updateExamPaperCountView,
  // editExamPaper,
} from "@/core/api/resource/exampaper";
import {
  getSchoolExamPaperRelationPage,
  getTypeCountOfSchoolPapers,
} from "@/core/api/resource/schoolResource";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";
import { setStore, getStore, removeStore } from "@/core/util/store";
import { fileDownloadByUrl } from "@/core/util/util";
const menuList = [
  {
    name: "校本真卷",
    type: 1,
    isClick: true,
    img: require("@/static/resources/collect.png"),
  },
  {
    name: "教师共享",
    type: 2,
    isClick: false,
    img: require("@/static/resources/group.png"),
  },
  {
    name: "定制试卷",
    type: 3,
    isClick: false,
    img: require("@/static/resources/up.png"),
  },
];

export default {
  name: "SchoolResource",
  components: {
    ExamPaperAnalysisForm,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    let resourceTypeList = ["试题", "试卷", "教材", "作业卡"];
    if (!hasHomework) {
      resourceTypeList = resourceTypeList.filter((item) => item != "作业卡");
    }
    return {
      fullscreenLoading: false,
      menuList: menuList,
      activeName: "first",
      gradeOptionsCopy: [],
      newKeys: 0,
      currentTypeId: 1, //0-试题 1-试卷 2-教案 3-教材 4-微课
      resourceTypeList: resourceTypeList,
      interactiveData: this.resourseTypeData, //tab切换时传输的数据、
      // 分类
      paperTypeData: [],
      paperCountOfTypes: [], //各类型试卷数量
      currentSelectedPaperType: 0,
      // 搜索
      searchItems: {
        level: "",
        resGradeId: 0,
        subjectId: "",
        keyword: "",
        startTime: "",
        endTime: "",
        rType: 0,
        type: 0,
        size: 10,
        current: 1,
        timeOrder: 2, //时间排序 1-升序 2-降序
        viewOrder: 2, //浏览排序 1-升序 2-降序
        orderType: 1, //1-按时间排序 2-按浏览数排序
        //  timeOrder viewOrder
      },
      isCurrentSortType: "new",
      // option数据
      examTypeOptions: examTypeOptions,
      gradeHighOptions: gradeHighOptions,
      primaryOptions: primaryOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeOptions: gradeOptions,
      subjectOptions: subjectOptions,
      levelOptions: levelOptions(),
      pageIndex: 1,
      pageData: [],
      searchYearList: [],
      examTypeOptionsCopy: [],
      dateValue: [],
      total: 0,
      analysisDialogVisible: false, // 试卷分析弹窗
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    this.resetBaseData();
    this.onloadList();
    this.getList();
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    downloadExamPaper(examPaper) {
      // console.log(examPaper);
      if (!examPaper.filePath && !examPaper.zipPath) {
        this.$message.error("试卷文件不存在！");
        return;
      }
      // 下载试卷增加试卷的浏览数
      updateExamPaperCountView(examPaper.id);
      // if (
      //   examPaper.filePath.endsWith(".doc") ||
      //   examPaper.filePath.endsWith(".docx") ||
      //   examPaper.filePath.endsWith(".pdf")
      // ) {
      //   this.downloadRequest(this.CDN + examPaper.filePath, examPaper.name);
      // } else {
      this.fullscreenLoading = true;
      getexampaperzip(examPaper.id)
        .then((response) => {
          fileDownloadByUrl(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
      // }
    },
    resourceTabClickHandle(type) {
      this.$parent.changeResourceType(type, this.searchItems, true);
    },
    resetBaseData() {
      // console.log("paper interactiveData..", this.interactiveData);
      if (!this.dataIsEmpty(this.interactiveData.type)) {
        this.currentTypeId = this.interactiveData.type;
        this.searchItems = Object.assign(
          this.searchItems,
          this.interactiveData.searchItems
        );
        this.resetSearchItemsByStorage();
      }
      let page_storage = getStore({ name: "schoolResourcePage" });
      // console.log("paper page_storage...", page_storage);
      if (page_storage) {
        this.searchItems.current = page_storage.page.current;
        this.pageIndex = page_storage.page.current;
        removeStore({ name: "schoolResourcePage" });
      }
    },
    resetSearchItemsByStorage() {
      this.currentSelectedPaperType = this.searchItems.type;
      this.dateValue = [this.searchItems.startTime, this.searchItems.endTime];
      if (this.searchItems.orderType == 2) {
        this.isCurrentSortType = "hot";
      }
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    //试卷分类数据处理
    paperTypeDataProcessing() {
      this.paperTypeData = this.gradeMiddleOptions;
      if (this.getLevelId == 3) {
        this.paperTypeData = this.gradeHighOptions;
      }
      if (this.getLevelId == 1) {
        this.paperTypeData = this.primaryOptions;
      }
      this.paperTypeData.forEach((item) => {
        item.count = 0; //一级节点统计数count
        item.children = []; //二级节点
        this.paperCountOfTypes.forEach((val) => {
          if (item.value == val.resGradeId) {
            item.count += parseInt(val.count);
            item.children.push(val);
          }
        });
        item.children.forEach((index) => {
          index.label = this.getLabelByValue(index.type, this.examTypeOptions);
        });
      });
    },
    getLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label;
        }
      });
      return _label;
    },
    cancel(val) {
      this.$confirm("确认取消收藏当前试卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavObj(val.id).then(() => {
            this.setList(val.id, true);
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“取消收藏”操作",
          });
        });
    },
    addList(val) {
      favObj(val.id).then(() => {
        this.setList(val.id, false);
        this.$message.success("收藏成功");
      });
    },
    setList(id, type) {
      this.pageData.map((index) => {
        if (id == index.id) {
          if (type) {
            index.relationId = 0;
          } else {
            index.relationId = 1;
          }
        }
      });
      // this.pageData = JSON.parse(JSON.stringify(this.pageData));
    },
    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.searchList();
      }
    },
    //获取试卷--分类
    getClassifiedPapers(grade, type) {
      this.searchItems.resGradeId = grade;
      this.searchItems.type = type;
      this.currentSelectedPaperType = type;
      this.pageList(1);
    },
    pageList(i) {
      this.searchItems.current = i;
      this.getList();
    },
    getList() {
      this.searchYearList.forEach((i) => {
        if (i.isClick && i.value == "001") {
          this.searchItems.year = 0;
          this.searchItems.beforeYear = this.searchYearList[4].value;
        }

        if (i.isClick && i.value != "001") {
          this.searchItems.year = i.value;
          this.searchItems.beforeYear = 0;
        }
      });
      if (this.dateValue && this.dateValue.length > 0) {
        this.searchItems.startTime = this.dateValue[0];
        this.searchItems.endTime = this.dateValue[1];
      } else {
        this.searchItems.startTime = "";
        this.searchItems.endTime = "";
      }

      this.searchItems.level = this.getLevelId;
      this.searchItems.subjectId = this.getSubjectId;
      this.fullscreenLoading = true;
      getSchoolExamPaperRelationPage(this.searchItems).then((res) => {
        this.fullscreenLoading = false;
        if (res.data.data) {
          this.pageData = res.data.data.records;
          this.total = res.data.data.total;
        } else {
          this.pageData = [];
          this.total = 0;
        }
      });
    },
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.searchItems.current = 1;
      this.getList();
    },
    searchList() {
      this.searchItems.current = 1;
      this.getList();
    },
    //获取最新试卷--时间排序
    getLastestPapers() {
      this.isCurrentSortType = "new";
      this.searchItems.orderType = 1;
      if (this.searchItems.timeOrder == 2) {
        this.searchItems.timeOrder = 1;
      } else {
        this.searchItems.timeOrder = 2;
      }
      this.searchList();
    },
    //获取最热试卷--浏览倒序
    getHotestPapers() {
      this.isCurrentSortType = "hot";
      this.searchItems.orderType = 2;
      if (this.searchItems.viewOrder == 2) {
        this.searchItems.viewOrder = 1;
      } else {
        this.searchItems.viewOrder = 2;
      }
      this.searchList();
    },
    // 初始化一下需要使用的搜索数组
    onloadList() {
      // let arr = JSON.parse(JSON.stringify(this.gradeOptions));
      // 搜索用的年级
      // this.gradeOptionsCopy.push({ label: "全部", value: 0, isClick: true });
      // arr.forEach(i => {
      // i.isClick = false;
      //   this.gradeOptionsCopy.push(i);
      // });

      //搜索用的类型
      let arrCopy = JSON.parse(JSON.stringify(this.examTypeOptions));
      this.examTypeOptionsCopy.push({
        // isClick: true,
        label: "全部",
        value: 0,
      });
      arrCopy.forEach((i) => {
        i.isClick = false;
        this.examTypeOptionsCopy.push(i);
      });
      // 各类型试卷数量
      getTypeCountOfSchoolPapers({
        level: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.paperCountOfTypes = res.data.data;
        this.paperTypeDataProcessing();
      });
      this.refreahInitData();
    },
    refreahInitData() {
      this.gradeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.resGradeId) {
          item.isClick = true;
        }
      });
      this.examTypeOptionsCopy.forEach((item) => {
        item.isClick = false;
        if (item.value == this.searchItems.type) {
          item.isClick = true;
        }
      });
    },
    menuClick(menu) {
      if (menu.isClick) {
        // 重复点击已选择的菜单
        return;
      }
      this.menuList.map((menuItem) => {
        menuItem.isClick = false;
        if (menu.type === menuItem.type) {
          menuItem.isClick = true;
          this.searchItems.current = 1;
          this.getList();
        }
      });
    },
    analyseExamPaper(examPaper) {
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.init(examPaper.id);
      });
    },
    viewPaper(examPaperId) {
      this.searchItems.paperType = this.searchItems.type;
      setStore({
        name: "currentItems",
        content: { tab_id: this.currentTypeId, search_items: this.searchItems },
      });
      setStore({
        name: "schoolResourcePage",
        content: { page: { current: this.searchItems.current } },
      });
      this.$router.push({
        name: "viewPaper",
        query: {
          id: examPaperId,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.resource-paper {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
}
</style>
<style scoped lang="scss">
.resource-paper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-box {
    padding: 20px 40px 20px 20px;
    width: 290px;
    border: 1px solid #dddddd;
    .label {
      font-size: 18px;
      font-weight: 700;
      color: #2474ed;
      margin-bottom: 10px;
      height: 25px;
      cursor: pointer;
    }
    .grade {
      margin-bottom: 20px;
      .grade-name {
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        height: 20px;
        margin-bottom: 8px;
      }
      .paper-type {
        padding-left: 20px;
        .type-name {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 20px;
          height: 20px;
          margin-bottom: 8px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            color: #2474ed;
          }
        }
        .current-type {
          color: #2474ed;
        }
      }
    }
    //set aside
    .click-car {
      margin-top: 12px;
    }
    .meun-list {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #e9f1f3;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 24px;
        margin-right: 4px;
      }
      .on {
        color: $primary-color;
      }
    }
  }
  .right-box {
    width: 900px;
    padding: 20px;
    border: 1px solid #dddddd;
    .resource-tab {
      display: flex;
      padding: 0 15px;
      border-bottom: 2px solid rgba(16, 198, 148, 0.2);
      margin-top: 32px;
      .tab-item {
        position: relative;
        padding-bottom: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        .type-name {
          height: 20px;
        }
        .underline {
          visibility: inherit;
          height: 3px;
          background-color: #2474ed;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          width: 28px;
        }
      }
      .active {
        color: #2474ed;
      }
    }
    .list-box {
      margin-top: 20px;
      .none-list {
        img {
          width: 400px;
          display: block;
          margin: 20px auto;
        }
        p {
          text-align: center;
          color: #999999;
        }
      }
    }
    .list-box-bottom {
      display: flex;
      justify-content: flex-end;
    }
    .sort-box {
      font-size: 14px;
      background: #f4f4f4;
      width: 860px;
      margin: 20px 0 10px;
      display: flex;
      border: 1px solid #e0e0e0;
      border-bottom: unset;
      .current {
        color: #2474ed;
        background-color: #ffffff;
      }
      .sort-type {
        font-weight: 500;
        cursor: pointer;
        padding: 10px 20px;
        border-right: 1px solid #e0e0e0;
        .sort-icon {
          width: 6px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.5);
        }
        .current-sort {
          color: #2474ed;
        }
      }
      .sorr-space {
        min-width: 100px;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    .search-item {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 8px;
      .el-radio {
        margin-bottom: 0;
        line-height: 24px;
      }
      .input-with-select {
        width: 300px;
      }
      .title {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
      }
      p {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .on {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .search-item-old {
      justify-content: space-between;
      padding: 0 14px;
    }
    .item-list {
      width: 860px;
      padding: 24px 20px;
      border: 1px solid #e0e0e0;
      margin-bottom: 12px;
      .el-icon-star-off,
      .el-icon-star-on {
        color: $primary-color;
        font-size: 18px;
        vertical-align: bottom;
        cursor: pointer;
      }
      .title {
        cursor: pointer;
        margin-right: 10px;
      }
      .my-collect-box {
        margin-top: 27px;
        display: flex;
        justify-content: space-between;
        .collect-box-left {
          span {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 8px;
          }
        }
        .btn-all {
          padding: 4px 8px;
        }
      }
      .my-group-box {
        margin-top: 27px;
        display: flex;
        justify-content: space-between;
        .group-box-left {
          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .group-box-right {
          span {
            color: rgba(0, 0, 0, 0.5);
            margin-right: 8px;
          }
          .title {
            cursor: pointer;
            color: $primary-color;
          }
        }
      }
    }
  }
}
</style>
