<template>
  <div v-loading="loading" class="homworkCard">
    <div class="wrapper">
      <div class="filterBoxHomework">
        <div class="allCard">
          <img src="@/static/\homework/all job card_icon12@1x.png" alt="" />
          全部作业卡
        </div>
        <div class="title">按教材章节查看</div>
        <el-cascader
          v-model="materialIds"
          :options="teachMaterialData"
          style="width: 100%; margin-bottom: 18px"
          clearable
          @change="materialChange"
        ></el-cascader>

        <div class="more-tree">
          <el-tree
            ref="tree"
            v-model="bookInfoId"
            :highlight-current="true"
            :data="bookInfo"
            node-key="id"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="content">
        <div class="content-filter">
          <div class="radios">
            <div class="label">年级：</div>
            <div
              v-for="(item, index) in gradeOptionsCopy"
              :key="index"
              :class="['radio', gradeId == item.value ? 'active' : '']"
              @click="gradeIdCliCK(item)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="radios">
            <div class="label">年份：</div>
            <div
              v-for="(item, index) in yearOptions"
              :key="index"
              :class="['radio', year == item.value ? 'active' : '']"
              @click="yearClick(item)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="input">
            搜索：<el-input
              v-model="keyword"
              clearable
              placeholder="请输入作业卡名称"
              @keyup.enter.native="search"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="search"
              >
              </i>
            </el-input>
          </div>
        </div>
        <el-tabs v-model="tabIndex" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in resourceTypeList"
            :key="index"
            :label="item"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
        <div class="homework-list">
          <template v-if="tableData.length">
            <div
              v-for="(item, index) in tableData"
              :key="index"
              class="homework"
            >
              <div class="homework-title" @click="reviewPdf(item)">
                {{ item.name }}
              </div>
              <div class="footer">
                <div class="message">
                  <div class="item">年级： {{ item.gradeName }}</div>
                  <div class="item">题数：{{ item.sheetDetailCount }}</div>
                  <div class="item">创建人：{{ item.createName || "-" }}</div>
                  <div class="item">时间：{{ item.createTime }}</div>
                </div>
                <div class="btns">
                  <distributeWork :item-data="item" />
                  <el-button :loading="copyLoading" @click="copyEdit(item)"
                    >复制编辑
                  </el-button>
                </div>
              </div>
            </div>
          </template>
          <no-data v-else message="暂无数据" style="margin: 20px 0"></no-data>
          <el-pagination
            :current-page="current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import distributeWork from "@/components/resource/distributeWork";
import { resPage, copySheet } from "@/core/api/homework/index";
import {
  gradeHighOptions,
  gradeMiddleOptions,
  primaryOptions,
} from "@/core/util/constdata";
import { levelyear } from "@/core/api/homework/index";
// import { getTeachingBooksList } from "@/core/api/resource/myResource";
import { getbooklistbylevelandsubject } from "@/core/api/resource/knowledgePointsPaper";
import { getTeachBookInfo } from "@/core/api/resource/resource";
import { filterSubject } from "@/core/util/util";
import { mapGetters } from "vuex";
export default {
  name: "HomworkCard",
  components: {
    distributeWork,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    const resourceTypeList = ["试题", "试卷", "教材", "作业卡"];

    return {
      subjectOptions: filterSubject(),
      form: {},
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      isIndeterminate: false,
      checkAll: false,
      classNums: [],
      classNumList: [],
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      resourceTypeList: [...resourceTypeList],
      yearOptions: [],
      year: -1,
      value: "",
      options: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      tabIndex: "3",
      dialogVisible: false,
      keyword: "",
      loading: false,
      copyLoading: false,
      gradeOptionsCopy: [],
      gradeId: null,
      teachMaterialData: [],
      bookInfo: [],
      bookInfoId: [],
      materialId: null,
      materialIds: [],
      gradeName: "",
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  mounted() {
    this.onloadList();
    this.getTeachbookrelation();
    this.getlevelyear();
    this.getData();
  },

  methods: {
    async getlevelyear() {
      const res = await levelyear();
      const result = res.data.data.every((item) => item.level == -1); // 等于-1的时候为管理员 客服账号 考务账号
      if (result) {
        const nowYear = new Date().getFullYear();
        const arr = new Array(4).fill("");
        let yearOptions = arr.map((item, index) => {
          return { label: nowYear - index, value: nowYear - index };
        });
        this.yearOptions = [
          { label: "全部", value: -1 },
          ...yearOptions,
          { label: "更早", value: -2 },
        ];
      } else {
        const arr = res.data.data.filter(
          (item) => item.level == this.getLevelId
        );
        const yearList = [...new Set(arr.flatMap((item) => item.yearList))].map(
          (item) => ({
            label: item,
            value: item,
          })
        );
        this.yearOptions = [{ label: "全部", value: -1 }, ...yearList];
        if (arr.length >= 5) {
          this.yearOptions.push({ label: "更早", value: -2 });
        }
      }
    },
    reviewPdf(item) {
      if (item.urls) {
        window.open(item.urls, "_blank");
      } else {
        this.$message({
          type: "warning",
          message: "该作业没有PDF",
          showClose: true,
        });
      }
    },
    async getBookInfo() {
      const res = await getTeachBookInfo(this.materialId);

      // this.bookInfo = res.data.data.resChapters;
      const { resChapters } = res.data.data;
      this.bookInfo = this.arrayToTree(resChapters);
    },
    arrayToTree(items) {
      const rootItems = [];
      const lookup = {};

      for (const item of items) {
        const id = item.number;
        const parentId = item.number.toString().slice(0, -1);
        lookup[id] = { ...item, children: [] };

        if (item.rank == 1) {
          rootItems.push(lookup[id]);
        } else {
          lookup[parentId].children.push(lookup[id]);
        }
      }

      return rootItems;
    },

    async getTeachbookrelation() {
      const res = await getbooklistbylevelandsubject({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      });
      this.teachMaterialData = [];
      let array = [];

      res.data.data.forEach((index) => {
        if (array.length == 0 || array.indexOf(index.editionId) == -1) {
          array.push(index.editionId);
          this.teachMaterialData.push({
            label: index.editionName,
            value: index.editionId,
            children: [],
          });
        }
        if (array.indexOf[index.editionId] != -1) {
          this.teachMaterialData.forEach((item) => {
            if (item.value == index.editionId) {
              item.children.push({
                label:
                  index.gradeName +
                  index.editionName +
                  index.termName +
                  index.name,
                value: index.id,
              });
            }
          });
        }
      });
    },
    getMaterialName(item) {
      const subject = this.subjectOptions.find(
        (it) => it.value == item.subjectId
      );
      return item.editionName + item.gradeName + subject.label + item.name;
    },
    materialChange() {
      if (this.materialIds.length) {
        this.materialId = this.materialIds[this.materialIds.length - 1];
        this.getBookInfo();
        this.getData();
      } else {
        this.bookInfo = [];
        this.materialId = null;
        this.bookInfoId = null;
        this.getData();
      }
    },
    onloadList() {
      this.gradeOptionsCopy.push({ label: "全部", value: null, isClick: true });
      if (this.getLevelId == 3) {
        gradeHighOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (this.getLevelId == 2) {
        gradeMiddleOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (this.getLevelId == 1) {
        primaryOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
    },
    copyEdit(item) {
      this.$confirm(
        "复制后可在此基础上进行编辑，确定复制当前作业卡内容吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.copyLoading = true;
            const res = await copySheet({ sheetId: item.id });
            const uuid = res.data.data;
            const { href } = this.$router.resolve({
              path: "/homework/makeSheet",
              query: {
                uuid: uuid,
              },
            });
            let timer = setTimeout(() => {
              window.open(href, "_blank");
              clearTimeout(timer);
            }, 0);
            this.$router.push({
              path: "/homework/myHomework",
            });
            this.getData();
            this.copyLoading = false;
          } catch {
            this.copyLoading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
            showClose: true,
          });
        });
    },
    async getData() {
      try {
        this.loading = true;
        const res = await resPage({
          size: this.pageSize,
          current: this.current,
          keyword: this.keyword,
          year: this.year,
          gradeId: this.gradeId,
          ifSelf: false,
          chapterIds: this.bookInfoId,
          bookId: this.materialId,
          levelId: this.getLevelId,
          subjectId: this.getSubjectId,
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    openModal() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getData();
    },
    handleNodeClick(data) {
      this.current = 1;
      this.bookInfoId = data.id;
      this.getData();
    },
    handleClick({ index }) {
      const params = {
        level: this.level,
        year: this.year || -1,
        keyword: this.keyword,
      };
      this.$parent.changeResourceType(index, params, true);
    },
    search() {
      this.current = 1;
      this.getData();
    },
    gradeIdCliCK(item) {
      this.gradeId = item.value;
      this.current = 1;
      this.getData();
    },
    yearClick(item) {
      this.current = 1;
      this.year = item.value;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.homworkCard {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    box-sizing: border-box;
    width: 1200px;
    padding: 18px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .filterBoxHomework {
      box-sizing: border-box;
      width: 300px;
      padding: 10px;
      border: 1px solid #e9e9e9;
      background: #ffffff;
      margin-right: 18px;
      .allCard {
        height: 50px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #2474ed;
        font-size: 16px;
        text-align: center;
        margin-bottom: 18px;
        line-height: 50px;
        img {
          margin-left: 8px;
        }
      }
      .title {
        font-size: 16px;
        color: #0a1119;
        margin-bottom: 18px;
      }
    }
    .content {
      flex: 1;
      &-filter {
        background: #f9fafc;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        padding: 18px;
        color: #0a1119;
        font-size: 14px;
        margin-bottom: 18px;
        .radios {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .label {
            margin-bottom: 16px;
          }
          .radio {
            min-width: 60px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #dee0e7;
            margin-bottom: 16px;
            cursor: pointer;
          }
          .radio + .radio {
            margin-left: 8px;
          }
          .active {
            border-color: #2474ed;
            color: #2474ed;
          }
        }
        .input {
          display: flex;
          align-items: center;
          .el-input {
            width: 220px;
          }
        }
      }
      .homework-list {
        .homework {
          background: #ffffff;
          border: 1px solid #e9e9e9;
          box-sizing: border-box;
          padding: 18px;
          &-title {
            color: #0a1119;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          &:hover {
            color: #2474ed;
            border-color: #2474ed;
            .homework-title {
              color: #2474ed;
            }
          }

          .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .message {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .item {
                box-sizing: border-box;
                padding-right: 16px;
                color: #5e6a7a;
                font-size: 14px;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 10px;
                }
              }
              .item + .item {
                margin-left: 16px;
              }
            }
          }
        }
        .homework + .homework {
          margin-top: 16px;
        }
      }
    }
  }
}
.checkbox {
  margin-bottom: 22px;
  box-sizing: border-box;
  padding: 0 16px;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 20px;
  }
}
</style>
<style lang="scss">
.more-tree {
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ///*<!--修改 滑块 -->*/
  &::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
  }
  .is-current.is-focusable {
    > .el-tree-node__content {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
  .el-tree-node:focus {
    > .el-tree-node__content {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
}
</style>
