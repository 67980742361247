<template>
  <span class="distributeWork">
    <el-button :disabled="disabled" @click="openModal">布置</el-button>
    <el-dialog
      title="布置作业"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="作业名称：" prop="name">
          <el-input
            v-model="form.name"
            maxlength="50"
            show-word-limit
            placeholder="请输入作业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="布置对象：">
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="学段："
                style="padding: 0"
                label-width="65px"
              >
                <el-select
                  v-model="form.levelId"
                  clearable
                  :disabled="isEdit"
                  placeholder="全部"
                  @change="levelChange"
                >
                  <el-option
                    v-for="item in levelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="入学年份："
                style="padding: 0"
                label-width="100px"
              >
                <el-select
                  v-model="form.year"
                  clearable
                  :disabled="isEdit"
                  placeholder="全部"
                  @change="yearChange"
                >
                  <el-option
                    v-for="item in yearOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <div class="checkbox">
          <el-checkbox
            v-if="classNumList.length"
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group v-model="classNums" @change="handleCheckClassNum">
            <el-checkbox
              v-for="item in classNumList"
              :key="item"
              :label="item.classNum"
              >{{ item.classNum }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <el-form-item label="作业时间：">
          <div class="timePicker">
            <el-date-picker
              v-model="form.beginTime"
              type="datetime"
              editable
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              v-model="form.endTime"
              type="datetime"
              editable
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="bntloading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </span>
</template>
<script>
import {
  homeworkClasNums,
  publishHomework,
  levelyear,
} from "@/core/api/homework/index";
import { getStore } from "@/core/util/store";
import { initYearOtions } from "@/views/studentsHomework/func";
import { levelOptions } from "@/core/util/constdata";
import moment from "moment";
export default {
  name: "DistributeWork",
  props: {
    hasIcon: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      dialogVisible: false,
      form: {},
      checkAll: false,
      isIndeterminate: false,
      classNums: [],
      classNumList: [],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      levelOptions: [],
      yearOptions: [...yearList],
      levelYearList: [],
      bntloading: false,
      userInfo: {},
    };
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    async getlevelyear() {
      const res = await levelyear();
      this.levelYearList = res.data.data;
      initYearOtions.bind(this)(this.form.levelId);
      this.initLevelOptions();
    },
    initLevelOptions() {
      const levelList = levelOptions();
      const result = this.levelYearList.every((item) => item.level == -1); // 等于-1的时候为管理员 客服账号 考务账号
      if (result) {
        this.levelOptions = levelList;
      } else {
        const levels = [
          ...new Set(this.levelYearList.map((item) => item.level)),
        ];
        this.levelOptions = levelList.filter((item) =>
          levels.includes(Number(item.value))
        );
      }
      initYearOtions.bind(this)(this.form.level);
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
      this.classNums = [];
      this.classNumList = [];
    },
    levelChange() {
      this.getClassNumList();
      initYearOtions.bind(this)(this.form.levelId);
    },
    yearChange() {
      this.getClassNumList();
    },
    handleCheckAllChange(val) {
      this.checkAll = val;
      this.classNums = val
        ? this.classNumList.map((item) => item.classNum)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckClassNum(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.classNumList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classNumList.length;
    },
    async openModal() {
      await this.getlevelyear();
      this.dialogVisible = true;
      this.form = {
        beginTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment().add(2, "day").format("YYYY-MM-DD 22:00:00"),
        name: this.itemData.name,
      };
      let storage = sessionStorage.getItem("DistributeWork");
      this.classNums = [];
      this.classNumList = [];

      if (storage) {
        const data = JSON.parse(storage);
        const levelValues = this.levelOptions.map((item) => item.value);
        this.form.levelId = levelValues.includes(data.levelId)
          ? data.levelId
          : "";
        this.form.year = data.year;
        if (data.year && data.levelId) {
          this.getClassNumList();
        }
      }

      this.checkAll = false;
    },
    async getClassNumList() {
      if (this.form.year && this.form.levelId) {
        const res = await homeworkClasNums({
          level: this.form.levelId,
          year: this.form.year,
        });
        this.classNumList = res.data.data;
      } else {
        this.classNumList = [];
      }
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.bntloading = true;
            await publishHomework({
              ...this.form,
              subjectId: this.itemData.subjectId,
              classNums: this.classNums.join(","),
              sheetId: this.itemData.id,
            });
            this.$message({
              type: "success",
              message: "已成功布置作业",
              showClose: true,
            });
            sessionStorage.setItem(
              "DistributeWork",
              JSON.stringify({
                levelId: this.form.levelId,
                year: this.form.year,
              })
            );
            this.dialogVisible = false;
            this.bntloading = false;
          } catch {
            this.bntloading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.distributeWork {
  margin-right: 10px;
}
.checkbox {
  margin-bottom: 22px;
  box-sizing: border-box;
  padding: 0 16px;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 20px;
  }
}
</style>
