<!-- 校本资源全部Tab有(页面顺序排列)：0-试题 1-试卷 2-教材 3-作业卡 根据需要随时调整 -->
<template>
  <div class="schoolResource">
    <!-- 试题 -->
    <TestQuestions
      v-if="currentTypeId == 0"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></TestQuestions>
    <!-- 试卷 -->
    <teachingPaper
      v-if="currentTypeId == 1"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></teachingPaper>
    <!-- 教案|课件 -->
    <!-- <teachingPlan
      v-if="currentTypeId == 2"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></teachingPlan> -->
    <!-- 教材 -->
    <teachingMaterial
      v-if="currentTypeId == 2"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></teachingMaterial>
    <!-- 作业卡 -->
    <homeworkCard
      v-if="currentTypeId == 3"
      :resourse-type-data="interactiveData"
    />
    <!-- 微课 -->
    <!-- <MicroLessons
      v-if="currentTypeId == 4"
      :key="newKeys"
      :resourse-type-data="interactiveData"
    ></MicroLessons> -->
  </div>
</template>

<script>
import {
  examTypeOptions,
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
  gradeOptions,
} from "@/core/util/constdata";
import { mapGetters } from "vuex";
import teachingPaper from "@/components/resource/teaching-paper";
// import teachingPlan from "@/components/resource/teaching-plan";
import teachingMaterial from "@/components/resource/teaching-material";
import homeworkCard from "@/components/resource/homeworkCard";
// import MicroLessons from "@/components/resource/micro-lessons";
import TestQuestions from "@/components/resource/test-questions";
import { getStore, removeStore } from "@/core/util/store";
const menuList = [
  {
    name: "校本真卷",
    type: 1,
    isClick: true,
    img: require("@/static/resources/collect.png"),
  },
  {
    name: "教师共享",
    type: 2,
    isClick: false,
    img: require("@/static/resources/group.png"),
  },
  {
    name: "定制试卷",
    type: 3,
    isClick: false,
    img: require("@/static/resources/up.png"),
  },
];
// const resourceTypeList = ["试题", "试卷", "教案", "教材","微课"];
export default {
  name: "SchoolResource",
  components: {
    teachingPaper,
    // teachingPlan,
    teachingMaterial,
    // MicroLessons,
    TestQuestions,
    homeworkCard,
  },
  data() {
    return {
      menuList: menuList,
      activeName: "first",
      gradeOptionsCopy: [],
      newKeys: 0,
      currentTypeId: 1, //对应resourceTypeList
      interactiveData: {}, //tab切换时传输的数据、
      // 分类
      paperTypeData: [],
      paperCountOfTypes: [], //各类型试卷数量
      currentSelectedPaperType: 0,
      // 搜索
      searchItems: {
        level: "",
        resGradeId: 0,
        subjectId: "",
        keyword: "",
        startTime: "",
        endTime: "",
        // beforeYear: 0,
        // year: 0,
        type: 0,
        size: 10,
        current: 1,
        timeOrder: "2",
        viewOrder: "1",
        //  timeOrder viewOrder
      },
      questionItems: {},
      // option数据
      examTypeOptions: examTypeOptions,
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      gradeOptions: gradeOptions,
      subjectOptions: subjectOptions,
      levelOptions: levelOptions(),
      pageIndex: 1,
      pageData: [],
      searchYearList: [],
      examTypeOptionsCopy: [],
      dateValue: [],
      total: 0,
      analysisDialogVisible: false, // 试卷分析弹窗
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    if (this.$route.query.currentTypeId) {
      this.currentTypeId = 3;
    }
  },
  methods: {
    getBaseData() {
      let tab_data = getStore({ name: "currentItems" });
      removeStore({ name: "currentItems" });
      if (tab_data) {
        this.searchItems.type = tab_data.search_items.paperType;
        this.searchItems = Object.assign(
          this.searchItems,
          tab_data.search_items
        );
        this.changeResourceType(tab_data.tab_id, this.searchItems, false);
      }
    },
    changeResourceType(type, items, tab_click) {
      // console.log("index..type=", type, ",currentTypeId=", this.currentTypeId);
      // console.log("index..items=", items);

      if (
        type != this.currentTypeId ||
        (type == this.currentTypeId && !tab_click)
      ) {
        this.searchItems = items;

        //试卷
        if (type != 0 && this.currentTypeId == 0) {
          this.searchItems.paperType = items.type;
        }
        if (type == 0) {
          this.searchItems.type = items.paperType;
        }

        if (tab_click) {
          this.searchItems.current = 1;
        }
        this.currentTypeId = type;
        this.interactiveData = {
          type: this.currentTypeId,
          searchItems: this.searchItems,
        };
        this.newKeys = new Date().getTime();
      }
    },
    getLabelByValue(value, source) {
      let _label = "";
      source.forEach((item) => {
        if (item.value == value) {
          _label = item.label;
        }
      });
      return _label;
    },
  },
};
</script>
<style lang="scss">
.schoolResource {
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
    color: $primary-color;
  }
}
</style>
<style scoped lang="scss">
.schoolResource {
  // margin-left: calc(100vw - 100%); //避免页面有滚动条时整体的跳动
  .resource-paper {
    width: 1220px;
    margin: 10px auto 50px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left-box {
      padding: 20px 40px 20px 20px;
      width: 290px;
      border: 1px solid #dddddd;
      .label {
        font-size: 18px;
        font-weight: 700;
        color: #2474ed;
        margin-bottom: 10px;
        height: 25px;
        cursor: pointer;
      }
      .grade {
        margin-bottom: 20px;
        .grade-name {
          font-size: 14px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          height: 20px;
          margin-bottom: 8px;
        }
        .paper-type {
          padding-left: 20px;
          .type-name {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 20px;
            height: 20px;
            margin-bottom: 8px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0px;
            }
            &:hover {
              color: #2474ed;
            }
          }
          .current-type {
            color: #2474ed;
          }
        }
      }
      //set aside
      .click-car {
        margin-top: 12px;
      }
      .meun-list {
        display: flex;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #e9f1f3;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
        img {
          width: 24px;
          margin-right: 4px;
        }
        .on {
          color: $primary-color;
        }
      }
    }
    .right-box {
      width: 900px;
      padding: 20px;
      border: 1px solid #dddddd;
      .resource-tab {
        display: flex;
        padding: 0 15px;
        border-bottom: 2px solid rgba(16, 198, 148, 0.2);
        margin-top: 32px;
        .tab-item {
          position: relative;
          padding-bottom: 5px;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 22px;
          }
          .type-name {
            height: 20px;
          }
          .underline {
            visibility: inherit;
            height: 3px;
            background-color: #2474ed;
            border-radius: 2px;
            position: absolute;
            top: 24px;
            width: 28px;
          }
        }
        .active {
          color: #2474ed;
        }
      }
      .list-box {
        margin-top: 20px;
        .item-list {
          .title {
            cursor: pointer;
          }
        }
        .none-list {
          img {
            width: 400px;
            display: block;
            margin: 20px auto;
          }
          p {
            text-align: center;
            color: #999999;
          }
        }
      }
      .list-box-bottom {
        display: flex;
        justify-content: flex-end;
      }
      .search-item {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 8px;
        .el-radio {
          margin-bottom: 0;
          line-height: 24px;
        }
        .input-with-select {
          width: 300px;
        }
        .title {
          margin: 0 5px 3px 5px;
          padding: 2px 10px;
          color: #333;
        }
        p {
          margin: 0 5px 3px 5px;
          padding: 2px 10px;
          color: #333;
          cursor: pointer;
          &:hover {
            background: $primary-color;
            color: #fff;
            border-radius: 3px;
          }
        }
        .on {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .search-item-old {
        justify-content: space-between;
        padding: 0 14px;
      }
      .item-list {
        width: 860px;
        padding: 24px 20px;
        border: 1px solid #e0e0e0;
        margin-bottom: 12px;
        .el-icon-star-off,
        .el-icon-star-on {
          color: $primary-color;
          font-size: 18px;
          vertical-align: bottom;
          cursor: pointer;
        }
        .my-collect-box {
          margin-top: 27px;
          display: flex;
          justify-content: space-between;
          .collect-box-left {
            span {
              color: rgba(0, 0, 0, 0.5);
              margin-right: 8px;
            }
          }
          .btn-all {
            padding: 4px 8px;
          }
        }
        .my-group-box {
          margin-top: 27px;
          display: flex;
          justify-content: space-between;
          .group-box-left {
            span {
              margin-right: 8px;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          .group-box-right {
            span {
              color: rgba(0, 0, 0, 0.5);
              margin-right: 8px;
            }
            .title {
              cursor: pointer;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
</style>
