<template>
  <div v-loading.fullscreen.lock="pageLoading" class="knowledgePointsPaper">
    <div :key="searchKey" class="left-box">
      <div class="type-box">
        <div
          v-for="(v, i) in typeList"
          :key="i"
          :class="{ on: typeVal == v.value }"
          @click="checkTypeList(v.value)"
        >
          {{ v.name }}
        </div>
      </div>
      <!-- 左边知识点组卷的盒子 -->
      <template v-if="typeVal == 1">
        <div class="tree-type-box">
          <template v-if="!treeType">知识点目录</template>
          <el-switch
            v-else
            v-model="knowType"
            active-text="交集"
            inactive-text="并集"
          >
          </el-switch>
          <el-switch v-model="treeType" active-text="多选" inactive-text="单选">
          </el-switch>
        </div>

        <el-input
          v-model="knowVal"
          placeholder="请输入内容"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchKnow"
          ></el-button>
        </el-input>

        <div class="tree-box" :class="{ 'more-tree': !treeType }">
          <el-tree
            ref="tree"
            :key="newKeys"
            :data="knowledgeData"
            :show-checkbox="treeType"
            node-key="number"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="twoTreeArray"
            :props="defaultProps"
            :filter-node-method="filterNode"
            @check="elTreeChange"
            @node-click="nodeClick"
          >
          </el-tree>
        </div>
      </template>
      <template v-else>
        <el-cascader
          v-model="value"
          :options="geteditionlistData"
          @change="handleChange"
        ></el-cascader>
        <div class="tree-box more-tree">
          <el-tree
            ref="tree"
            :data="getbookchapterList"
            node-key="number"
            :props="defaultProps"
            :filter-node-method="filterNode"
            @node-click="editionClick"
          >
          </el-tree>
        </div>
      </template>
    </div>
    <div class="right-box">
      <div class="search-box">
        <div class="input-box">
          <el-input
            v-model="question"
            placeholder="请输入内容"
            class="input-with-select"
            :debounce="500"
            @keyup.enter.native="searchList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </div>
        <div class="search-item">
          <div class="title">题型：</div>
          <template v-for="(v, i) in questionList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, questionList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">难度：</div>
          <template v-for="(v, i) in difficultyList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, difficultyList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">年份：</div>
          <template v-for="(v, i) in searchYearList">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, searchYearList)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">考试类型：</div>
          <template v-for="(v, i) in examTypeOptions">
            <p
              :key="i"
              :class="{ on: v.isClick }"
              @click="setItem(v, examTypeOptions)"
            >
              {{ v.label }}
            </p>
          </template>
        </div>
        <div class="search-item">
          <div class="title">过滤：</div>
          <el-checkbox-group v-model="checkList" @change="filterList">
            <el-checkbox :label="1">图片题</el-checkbox>
            <el-checkbox :label="2">无答案解析</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- 类别tab -->
      <div class="resource-tab">
        <div
          v-for="(item, key) in resourceTypeList"
          :key="key"
          class="tab-item"
          :class="{ active: currentTypeId == key }"
          @click="resourceTabClickHandle(key)"
        >
          <div class="type-name">{{ item }}</div>
          <div v-if="currentTypeId == key" class="underline"></div>
        </div>
      </div>
      <div class="menu-box">
        <div
          v-for="(v, i) in sortTypeList"
          :key="i"
          :class="{ on: v.isClick }"
          @click="setItem(v, sortTypeList)"
        >
          {{ v.name }}
        </div>
        <!-- <el-switch
          v-model="noImg"
          active-text="包含图片题"
          inactive-text="不包含图片题"
        >
        </el-switch>
        <el-switch
          v-model="hasAnswer"
          active-text="所有题"
          inactive-text="有答案解析"
        >
        </el-switch> -->
      </div>
      <div v-if="loading" v-loading="loading" class="loading-box"></div>
      <div v-if="!loading" class="question-box">
        <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
          <div class="question-id">试题ID：{{ v.id }}</div>
          <div style="padding: 0 12px 12px" v-html="v.question"></div>
          <div class="btm-box">
            <div class="tips">
              <span> 收录于{{ v.createTime | setTimeStyle }}</span>
              <span>{{ v.typeName }}</span>
              <span> {{ v.difficulty | setName(difficultyListCopy) }}</span>
            </div>

            <div class="btn-box">
              <span :class="{ succ: v.isRelation }" @click="relation(v)">
                <template v-if="v.isRelation == 1">已</template>收藏</span
              >
              <span @click="show(v)">解析</span>
              <span @click="situation(v)">考情</span>
              <el-button
                v-if="planQuestionList.indexOf(v.id) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>
          </div>
          <!-- 考情 -->
          <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
            <template
              v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
            >
              数据尚未同步
            </template>
            <template v-else>
              <p>
                <span class="situation-box-name">考情：</span>本题共
                <span>{{ v.situation.countReply }}</span> 人使用,共{{
                  v.situation.examNum
                }}场考试
              </p>
              <div
                v-for="(m, n) in v.situation.anyExamUseQuestion"
                :key="n"
                class="situation-list"
              >
                <div class="situation-list-left">
                  <p>{{ m.examName }}</p>
                  <div>
                    <span class="situation-list-name">{{ m.examDate }}</span>
                    <span> | </span>
                    <span class="situation-list-name"
                      >共{{ m.countReply }}人参考</span
                    >
                    <span> | </span>
                    <span class="situation-list-name">{{ m.schoolLevel }}</span>
                  </div>
                </div>
                <div class="situation-list-right">
                  {{ m.scoreRate | setNumber }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
            <div v-if="v.newQuestion" class="new-question-box">
              <p v-if="v.newQuestion" class="question-tips">
                当前试题没有找到答案解析，已推荐同类题
              </p>
              <div class="analysis-item">
                【同类题】
                <div v-html="v.newQuestion"></div>
              </div>
              <el-button
                v-if="planQuestionList.indexOf(v.newId) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v, 1)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v, 1)"
              >
                移除试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
            </div>

            <div
              v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
              class="know-box analysis-item"
            >
              【知识点】
              <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                {{ m }}
              </div>
            </div>
            <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
              【答案】
              <div v-html="v.answer"></div>
            </div>
            <div
              v-if="v.analysis && v.analysis.length > 0"
              class="analysis-item"
            >
              【解答】
              <div v-html="v.analysis"></div>
            </div>
            <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
              【分析】
              <div v-html="v.analyse"></div>
            </div>
            <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
              【点评】
              <div v-html="v.ability"></div>
            </div>
            <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
          </div>
        </div>
      </div>
      <div v-if="examquestionData.length == 0 && !loading" class="none-list">
        <img src="@/static/resources/none.svg" alt="" />
        <p>暂未找到数据</p>
      </div>
      <el-pagination
        v-if="examquestionData.length > 0 && !loading"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="current"
        @current-change="pageList"
      >
      </el-pagination>
    </div>
    <testBasket
      :key="componentsKey"
      :plan-id="planId"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
      @getNewList="getNewList"
    ></testBasket>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getQuestionPoint } from "@/core/api/exam/examLore";
import {
  getbookchapterbyid,
  getbooklistbylevelandsubject,
  getSchoolQuestions,
  exampaperplanquestionDel,
  exampaperplanquestion,
  situation,
  answer,
  examquestionrelationMy,
  examquestionrelationDel,
} from "@/core/api/resource/knowledgePointsPaper";
import { getquestiontype } from "@/core/api/resource/breakdownPaper";
import {
  questionType,
  examTypeOptions,
  difficultyList,
  difficultyListCopy,
} from "@/core/util/constdata";
import testBasket from "@/views/resource/knowledgePointsPaper/components/testBasket";
import { getStore, removeStore } from "@/core/util/store";
const typeList = [
  { name: "知识点", value: 1 },
  { name: "章节", value: 2 },
];
const sortTypeList = [
  { name: "最新", value: 1, isClick: true },
  { name: "最热", value: 2, isClick: false },
];

export default {
  name: "KnowledgePointsPaper",
  components: {
    testBasket,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    let resourceTypeList = ["试题", "试卷", "教材", "作业卡"];
    if (!hasHomework) {
      resourceTypeList = resourceTypeList.filter((item) => item != "作业卡");
    }
    return {
      //tab
      currentTypeId: 1, //0-试题 1-试卷 2-教案 3-教材 4-微课
      resourceTypeList: resourceTypeList,
      interactiveData: this.resourseTypeData, //tab切换时传输的数据、
      // 搜索
      questionItems: {
        size: 10,
        current: 1,
        level: "",
        subjectId: "",
        question: "",
        isSingle: 0,
        isUnion: 0,
        noImg: 0,
        hasAnswer: 0,
        typeName: -1,
        difficulty: "0-1",
        year: -1,
        examType: -1,
        sortType: 1,
        knowledgeIds: "",
        chapterIds: "",
        jyeooId: "",
        checkList: [],
      },
      pageLoading: false,

      searchKey: 111,
      checkList: [1],
      loading: false,
      // 判断列表是否显示解析
      showListArr: [],
      // 判断是否显示考情
      situationArr: [],
      typeList: typeList,
      sortTypeList: sortTypeList,
      typeVal: 1,
      knowVal: "",
      value: [],
      treeType: false,
      knowType: true,
      noImg: true,
      hasAnswer: true,
      knowledgeData: [],
      loreTureArrid: [],
      twoTreeArray: [],
      newKeys: 0,
      defaultProps: {
        children: "child",
        label: "name",
      },
      knowledge: [],
      geteditionlistData: [],
      // 章节原始数据
      getbookchapterData: [],
      // 树形图
      getbookchapterList: [],

      // 搜索
      searchYearList: [],
      questionType: questionType,
      questionList: [],
      difficultyList: difficultyList,
      difficultyListCopy: difficultyListCopy,
      examTypeOptions: examTypeOptions,
      examquestionData: [],
      total: 0,
      componentsKey: 1,
      planId: 0,
      chapterId: "",
      current: 1,
      question: "",
      planQuestionList: [],
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    knowType() {
      this.current = 1;
      this.getList();
    },
    knowVal() {
      this.$refs.tree.filter(this.knowVal);
    },
    treeType() {
      this.knowledge = [];
      this.current = 1;
      this.getList();
    },
    twoTreeArray() {
      this.twoTreeArray = this.knowledge;
    },
    loreTureArrid() {
      this.loreTureArrid = this.knowledge;
    },
  },
  created() {
    // 通过科目、学段查询题型
    this.questionList = [{ label: "全部", value: -1, isClick: true }];
    this.pageLoading = true;
    getquestiontype({
      levelId: this.getLevelId,
      subjectId: this.getSubjectId,
    })
      .then((res) => {
        res.data.data.forEach((index) => {
          this.pageLoading = false;
          this.questionList.push({
            value: index.name,
            label: index.name,
            isClick: false,
          });
        });
        this.$nextTick(() => {
          this.setSearchList();
          this.getToList();
          this.geteditionlist();
        });
      })
      .catch(() => {
        this.pageLoading = false;
      });
  },
  methods: {
    //tab
    resourceTabClickHandle(type) {
      // console.log("resourceTabClickHandle questionItems==", this.questionItems);
      this.questionItems.checkList = this.checkList;
      this.questionItems.current = this.current;
      this.questionItems.question = this.question; //右侧筛选区搜索
      // this.questionItems.typeVal = this.typeVal;
      // this.questionItems.knowVal = this.knowVal;
      this.$parent.changeResourceType(type, this.questionItems, true);
    },
    resetBaseData() {
      this.questionItems.subjectId = this.getSubjectId;
      this.questionItems.level = this.getLevelId;
      // console.log("interactiveData==", this.interactiveData);
      if (
        !this.dataIsEmpty(this.interactiveData.type) &&
        this.interactiveData.type == 0
      ) {
        this.currentTypeId = this.interactiveData.type;
        this.questionItems = Object.assign(
          this.questionItems,
          this.interactiveData.searchItems
        );
        this.resetQuestionItemsByStorage();
      }
      let page_storage = getStore({ name: "schoolResourcePage" });
      if (page_storage) {
        this.questionItems.current = page_storage.page.current;
        this.pageIndex = page_storage.page.current;
        removeStore({ name: "schoolResourcePage" });
      }
    },
    resetQuestionItemsByStorage() {
      // console.log("questionItems==", this.questionItems);
      this.question = this.questionItems.question; //搜索
      this.resetSearchItems(this.questionList, this.questionItems.typeName); //题型
      this.resetSearchItems(this.difficultyList, this.questionItems.difficulty); //难度
      this.resetSearchItems(this.searchYearList, this.questionItems.year); // 年份
      this.resetSearchItems(this.examTypeOptions, this.questionItems.examType); // 考试类型
      this.checkList = this.questionItems.checkList; //过滤
      this.resetSearchItems(this.sortTypeList, this.questionItems.sortType); // 排序  最新  最热
      this.current = this.questionItems.current; //页码
      // 左侧--el-tree组件自带坑(数据能对但是页面上没有表现出来被选中)，暂时注释掉已选参数保存
      // this.typeVal = this.questionItems.typeVal; //1-知识点 2-章节
      // this.treeType = this.questionItems.isSingle == 0 ? true : false;
      // this.knowType = this.questionItems.isUnion == 0 ? true : false;
      // this.knowVal = this.questionItems.knowVal;
      // this.knowledge = this.questionItems.knowledgeIds;
      // this.loreTureArrid = this.questionItems.knowledge; //知识点默认选中
      // this.twoTreeArray = this.questionItems.knowledge; //知识点默认展开
    },
    resetSearchItems(sourceData, fieldValue) {
      sourceData.forEach((item) => {
        if (item.value == fieldValue) {
          item.isClick = true;
        } else {
          item.isClick = false;
        }
      });
      sourceData = JSON.parse(JSON.stringify(sourceData));
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },

    filterList() {
      this.current = 1;
      this.getList();
    },
    setQuestionList(val) {
      this.planQuestionList = val;
    },
    pageList(i) {
      this.current = i;
      this.getList();
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
      });
    },
    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };
      exampaperplanquestionDel(data).then(() => {
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        this.componentsKey = new Date().getTime();
      });
    },
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    getNewList() {
      this.getList();
    },
    //设置planid
    setPlanid(planId) {
      this.planId = planId;
      this.getList();
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };

      exampaperplanquestion(data).then(() => {
        this.$message({
          message: "加入成功",
          type: "success",
        });
        this.componentsKey = new Date().getTime();
      });
    },
    onValue(list) {
      let value = "";
      list.forEach((index) => {
        if (index.isClick) {
          value = index.value;
        }
      });
      return value;
    },
    // getSearchItemsForQuesList(){

    // },
    getList() {
      let data = {
        size: 10,
        current: this.current,
        level: this.getLevelId,
        subjectId: this.getSubjectId,
        // planId: this.planId,
        question: this.question,
      };
      this.situationArr = [];
      this.showListArr = [];
      data.isSingle = this.treeType ? 0 : 1;
      // if (this.treeType) {
      data.isUnion = this.knowType ? 0 : 1;
      // }
      if (!this.treeType) {
        data.isUnion = 1;
      }
      if (this.checkList && this.checkList.indexOf(1) != -1) {
        data.noImg = 1;
      } else {
        data.noImg = 0;
      }
      if (this.checkList && this.checkList.indexOf(2) != -1) {
        data.hasAnswer = 1;
      } else {
        data.hasAnswer = 0;
      }
      //题型
      data.typeName = this.onValue(this.questionList);
      // 难度
      data.difficulty = this.onValue(this.difficultyList);
      // 年份
      data.year = this.onValue(this.searchYearList);
      // 考试类型
      data.examType = this.onValue(this.examTypeOptions);
      // 排序  最新  最热
      data.sortType = this.onValue(this.sortTypeList);
      // typeVal 为1就是知识点 ，为2就是章节
      if (this.typeVal == 1) {
        data.knowledgeIds = this.knowledge;
        data.chapterIds = "";
        if (data.knowledgeIds.length > 15) {
          this.$message({
            message: "所选知识点不能超过15个",
            type: "warning",
          });
          return;
        }
      } else {
        data.knowledgeIds = [];
        data.chapterIds = this.chapterId;
        let arr = this.getbookchapterData.filter(
          (item) => item.number == data.chapterIds
        );
        if (arr.length > 0) {
          data.jyeooId = arr[0].jyeooId;
        } else {
          data.jyeooId = "";
        }
      }
      this.loading = true;
      this.questionItems = data;
      getSchoolQuestions(this.questionItems)
        .then((res) => {
          this.loading = false;
          this.examquestionData = res.data.data.records;

          this.total = res.data.data.total;
          this.showListArr = [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换
    setItem(val, list) {
      list.map((index) => {
        index.isClick = false;
        if (index.value == val.value) {
          index.isClick = true;
        }
      });
      this.current = 1;
      this.getList();
    },
    searchList() {
      this.current = 1;
      this.searchKey = new Date().getTime();
      this.knowledge = [];
      this.chapterId = "";
      // difficultyList searchYearList examTypeOptions
      this.difficultyList.map((index) => {
        index.isClick = false;
      });
      this.difficultyList[0].isClick = true;
      this.searchYearList.map((index) => {
        index.isClick = false;
      });
      this.searchYearList[0].isClick = true;
      this.examTypeOptions.map((index) => {
        index.isClick = false;
      });
      this.examTypeOptions[0].isClick = true;
      this.sortTypeList[0].isClick = true;
      this.sortTypeList[1].isClick = false;
      this.checkList = [1];
      this.getList();
    },
    setSearchList() {
      // 搜索用的年份
      let year = new Date().getFullYear();
      this.searchYearList.push({
        label: "全部",
        value: -1,
        isClick: true,
      });
      for (let i = 0; i < 4; i++) {
        this.searchYearList.push({
          label: year - i,
          value: year - i,
          isClick: false,
        });
      }
      this.searchYearList.push({
        label: "更早",
        value: -2,
        isClick: false,
      });
      let arr = [
        {
          value: "0-1",
          label: "全部",
          isClick: true,
        },
      ];
      //难度列表循环
      this.difficultyList.map((item) => {
        item.isClick = false;
      });
      this.difficultyList = arr.concat(this.difficultyList);
      this.difficultyList = JSON.parse(JSON.stringify(this.difficultyList));

      // this.questionList = [{ label: "全部", value: -1, isClick: true }].concat(
      //   this.questionList
      // );
      this.examTypeOptions.map((item) => {
        item.isClick = false;
      });
      this.examTypeOptions = [
        { label: "全部", value: -1, isClick: true },
      ].concat(this.examTypeOptions);
      this.examTypeOptions = JSON.parse(JSON.stringify(this.examTypeOptions));
      //
      this.$nextTick(() => {
        this.resetBaseData();
        this.getList();
      });
    },
    // 章节点击
    editionClick(val) {
      this.chapterId = val.number;
      this.current = 1;
      this.getList();
    },
    handleChange(val) {
      if (val.length < 2) {
        return;
      }
      this.getbookchapterbyid(val[1]);
    },
    // /通过科目、学段查询教材列表列表
    geteditionlist() {
      getbooklistbylevelandsubject({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.geteditionlistData = [];
        let array = [];
        res.data.data.forEach((index) => {
          if (array.length == 0 || array.indexOf(index.editionId) == -1) {
            array.push(index.editionId);
            this.geteditionlistData.push({
              label: index.editionName,
              value: index.editionId,
              children: [],
            });
          }
          if (array.indexOf[index.editionId] != -1) {
            this.geteditionlistData.forEach((item) => {
              if (item.value == index.editionId) {
                item.children.push({
                  label:
                    index.gradeName +
                    index.editionName +
                    index.termName +
                    index.name,
                  value: index.id,
                });
              }
            });
          }
        });
        if (this.geteditionlistData.length > 0) {
          this.value.push(this.geteditionlistData[0].value);
          this.value.push(this.geteditionlistData[0].children[0].value);
          this.getbookchapterbyid(this.value[1]);
        }
      });
    },
    // 通过id获取章节
    getbookchapterbyid(editionId) {
      getbookchapterbyid(editionId).then((res) => {
        this.getbookchapterList = [];
        this.getbookchapterData = res.data.data;
        let arrList = this.getbookchapterData;
        // 递归生成一个新树形节点
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.getbookchapterList.push(obj);
          });
      });
    },
    checkTypeList(i) {
      if (i == this.typeVal) {
        return;
      }
      this.typeVal = i;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //点击了当前项
    nodeClick(val) {
      //如果是多选就不管了
      if (this.treeType) {
        return;
      }
      this.knowledge = [];
      this.knowledge.push(val.number);
      this.current = 1;
      this.getList();
    },
    // 知识点搜索
    searchKnow() {
      this.$refs.tree.filter(this.knowVal);
    },
    elTreeChange() {
      this.knowledge = [];
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
      this.current = 1;
      if (this.knowVal == "") {
        this.knowledge = this.loreTureArrid;
        this.getList();
        return;
      }
      //如果存在搜索值，就过滤下
      this.loreArr.forEach((item) => {
        if (this.loreTureArrid.indexOf(item.number) != -1) {
          if (item.name.indexOf(this.knowVal) != -1) {
            this.knowledge.push(item.number);
          }
        }
      });
      this.getList();
      // this.knowVal
    },
    //组装知识点的树形结构
    getToList() {
      getQuestionPoint({
        level: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1 && i.name != "听力")
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeData.push(obj);
          });
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
.knowledgePointsPaper {
  img {
    max-width: 100%;
  }
  .input-with-select {
    border-radius: 4px;
    overflow: hidden;
    .el-button {
      background-color: #2474ed;
      border-radius: 0px 4px 4px 0;
      border: 1px solid #2474ed;
    }
    i {
      color: #ffffff;
    }
  }
  //多选就不高亮
  .more-tree {
    .is-current.is-focusable {
      > .el-tree-node__content {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    .el-tree-node:focus {
      > .el-tree-node__content {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
  }
  .tree-box {
    label {
      margin-bottom: 0;
    }
    //修改树形控件基础样式
    .el-tree-node > .el-tree-node__children {
      overflow: unset;
    }
  }
}
</style>

<style scoped lang="scss">
.knowledgePointsPaper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left-box,
  .right-box {
    padding: 20px;
    border: 1px solid #dddddd;
  }
  .left-box {
    width: 300px;
    padding: 12px;
    .input-with-select {
      margin: 12px 0;
    }
    .el-cascader {
      width: 100%;
      margin: 12px 0;
    }
    .tree-box {
      height: calc(100vh - 300px);
      width: 100%;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #dcdfe6;
      }
    }
    .tree-type-box {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      color: $primary-color;
    }
    .type-box {
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #dddddd;
      div {
        width: 50%;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-right: 1px solid #dddddd;
        }
      }
      .on {
        background: $primary-color;
        color: #ffffff;
      }
    }
  }
  .right-box {
    width: 900px;
    .menu-box {
      display: flex;
      margin-top: 20px;
      .el-switch {
        margin-left: 24px;
      }
      div {
        margin-right: 20px;
        cursor: pointer;
      }
      .on {
        color: $primary-color;
      }
    }
    .none-list {
      img {
        width: 400px;
        display: block;
        margin: 20px auto;
      }
      p {
        text-align: center;
        color: #999999;
      }
    }
    .loading-box {
      width: 100%;
      height: 600px;
    }
    .question-box {
      margin: 20px 0;
      .situation-box {
        padding: 12px;
        p {
          color: #606266;
        }
        .situation-box-name {
          color: $primary-color;
        }

        .situation-list {
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #e5e5e5;
          margin-bottom: 12px;
          .situation-list-left {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
            .situation-list-name {
              color: #2196f3;
            }
          }
          .situation-list-right {
            color: #2196f3;
          }
        }
      }
      .analysis-box {
        .new-question-box {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          padding: 12px;
          border-bottom: 1px dashed #dddddd;
          .question-tips {
            width: 100%;
            color: $primary-color;
          }
          .analysis-item {
            width: 80%;
            padding: 0px;
            border-bottom: none;
          }
        }
        .know-box {
          display: flex;
          align-items: center;
          div {
            color: #262626;
            padding: 0 5px;
            border-radius: 3px;
            margin-left: 12px;
            background: #e6f8f6;
          }
        }
      }
      .analysis-item {
        border-bottom: 1px dashed #dddddd;
        padding: 12px;
        &:last-child {
          border-bottom: none;
        }
      }
      .id-box {
        padding: 12px;
      }
      .btm-box {
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding-left: 24px;
        background: #f5f5f5;
        .tips {
          > span {
            padding-right: 12px;
            // cursor: pointer;
          }
        }
        .succ {
          color: $primary-color;
        }
        .el-button {
          height: 40px;
          border-radius: 0 !important;
          width: 140px;
        }
        .btn-box {
          > span {
            padding-right: 12px;
            cursor: pointer;
          }
        }
        p {
          color: $primary-color;
          margin-bottom: 0;
        }
      }
      .question-list {
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        border: 1px solid $primary-color;
        padding-top: 12px;
        overflow: hidden;
        position: relative;
        .question-id {
          color: $primary-color;
          padding: 0 12px;
        }
      }
    }
    .input-box {
      margin: 0 auto 20px;
      width: 450px;
    }
    .search-item {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 8px;
      align-items: center;
      .el-checkbox {
        margin-bottom: 0;
      }
      .input-with-select {
        width: 300px;
      }
      .title {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
      }
      p {
        margin: 0 5px 3px 5px;
        padding: 2px 10px;
        color: #333;
        cursor: pointer;
        &:hover {
          background: $primary-color;
          color: #fff;
          border-radius: 3px;
        }
      }
      .on {
        background: $primary-color;
        color: #fff;
        border-radius: 3px;
      }
    }
    .resource-tab {
      display: flex;
      padding: 0 15px;
      border-bottom: 2px solid rgba(16, 198, 148, 0.2);
      margin-top: 32px;
      .tab-item {
        position: relative;
        padding-bottom: 5px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 22px;
        }
        .type-name {
          height: 20px;
        }
        .underline {
          visibility: inherit;
          height: 3px;
          background-color: #2474ed;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          width: 28px;
        }
      }
      .active {
        color: #2474ed;
      }
    }
  }
}
</style>
