import { levelOptions } from "@/core/util/constdata";
export const initYearOtions = function (level) {
  const result = this.levelYearList.every((item) => item.level == -1); // 等于-1的时候为管理员 客服账号 考务账号
  if (result) {
    const nowYear = new Date().getFullYear();
    const arr = new Array(10).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: nowYear - index, value: nowYear - index };
    });
    this.yearOptions = [...yearOptions];
  } else {
    if (level) {
      const arr = this.levelYearList.filter((item) => item.level == level);
      this.yearOptions = [...new Set(arr.flatMap((item) => item.yearList))].map(
        (item) => ({
          label: item,
          value: item,
        })
      );
    } else {
      const years = [
        ...new Set(this.levelYearList.flatMap((item) => item.yearList)),
      ];
      this.yearOptions = years.map((item) => ({
        label: item,
        value: item,
      }));
    }
  }
};
export const initLevelOptions = function () {
  const levelList = levelOptions();
  const result = this.levelYearList.every((item) => item.level == -1); // 等于-1的时候为管理员 客服账号 考务账号
  if (result) {
    this.levelOptions = levelList;
  } else {
    const levels = [...new Set(this.levelYearList.map((item) => item.level))];
    this.levelOptions = levelList.filter((item) =>
      levels.includes(Number(item.value))
    );
  }
  const levels = this.levelOptions.map((item) => item.value.toString());
  if (this.searchData && this.searchData.level && this.searchData.year) {
    if (levels.includes(this.searchData.level)) {
      this.form.level = this.searchData.level;

      this.getClassNumList();
    } else {
      this.$emit("change", this.form);
    }
  } else if (this.userInfo.teacherRoleList) {
    const teacherRole = this.userInfo.teacherRoleList.find(
      (item) => item.type == 1
    );
    if (teacherRole && levels.includes(teacherRole.level.toString())) {
      this.form.level = teacherRole.level.toString();
      this.getClassNumList();
    } else {
      this.$emit("change", this.form);
    }
  } else {
    this.$emit("change", this.form);
  }
  initYearOtions.bind(this)(this.form.level);

  this.getSubjectList();
};
